<template>
  <div class="ingredient">
    <el-form label-position="top">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="Название" prop="title">
            <el-input v-model="ingredientData.title" autocomplete="off" placeholder="Мясо" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Штрихкод" prop="barcode">
            <el-input
              v-model="ingredientData.barcode"
              placeholder="9057383582"
              autocomplete="off"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Единицы измерения" prop="unit_id">
            <el-autocomplete
              v-model="ingredientData.unit.title"
              style="width: 100%"
              :fetch-suggestions="searchUnitFn"
              :debounce="600"
              placeholder="Категорию ингредиента"
              value-key="title"
              @select="selectUnitFn"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Категория ингредиента" prop="category_id">
            <el-autocomplete
              v-model="ingredientData.category.title"
              style="width: 100%"
              :fetch-suggestions="searchCategoryFn"
              :debounce="600"
              placeholder="Категорию ингредиента"
              value-key="title"
              :select-when-unmatched="true"
              @select="selectCategoryFn"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="Калории">
            <el-input v-model="ingredientData.calories" placeholder="Калории" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="Белки">
            <el-input v-model="ingredientData.proteins" placeholder="Белки" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="Жиры">
            <el-input v-model="ingredientData.fats" placeholder="Жиры" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="Углеводы">
            <el-input v-model="ingredientData.carbohydrates" placeholder="Углеводы" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="% потерь при запекании" prop="losses_baking">
            <el-input-number
              v-model="ingredientData.losses_baking"
              :min="0"
              :max="100"
              style="width: 100%"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="% потерь при варке" prop="losses_cooking">
            <el-input-number
              v-model="ingredientData.losses_cooking"
              :min="0"
              :max="10000"
              style="width: 100%"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="% потерь при жарке" prop="losses_frying">
            <el-input-number
              v-model="ingredientData.losses_frying"
              :min="0"
              :max="100"
              style="width: 100%"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="% потерь при отчистке" prop="losses_cleaning">
            <el-input-number
              v-model="ingredientData.losses_cleaning"
              :min="0"
              :max="100"
              style="width: 100%"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="% потерь при тушении" prop="losses_stew">
            <el-input-number
              v-model="ingredientData.losses_stew"
              :min="0"
              :max="10000"
              style="width: 100%"
            />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <div class="is-flex is-justify-content-flex-end">
            <el-button type="success" @click="saveFn"> Сохранить </el-button>
          </div>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { FETCH_UNITS } from '@/app/settings/units/shared/state/types/action_types';
import { appLocalStorage } from '@/app/shared/services';
import { UPDATE_INGREDIENT, FETCH_INGREDIENT_CATEGORY } from '../shared/state/types/action_types';
import { notifyMixin } from '../shared/mixins/notify-mixin';

export default {
  name: 'Ingredient',
  mixins: [notifyMixin],
  data() {
    return {
      ingredientData: {},
    };
  },
  computed: {
    ...mapState({
      categories: (state) => state.ingredientsState.ingredientsData.category,
      error: (state) => state.ingredientsState.ingredientsData.error,
      units: (state) => state.unitsState.unitsData.data,
    }),
  },
  created() {
    if (appLocalStorage.getItem('ingredient')) {
      this.ingredientData = { ...appLocalStorage.getItem('ingredient') };
    }
  },
  methods: {
    ...mapActions({
      update: UPDATE_INGREDIENT,
      fetchCategories: FETCH_INGREDIENT_CATEGORY,
      fetchUnits: FETCH_UNITS,
    }),
    async saveFn() {
      this.ingredientData = {
        ...this.ingredientData,
        calories: this.ingredientData.calories.toString().replace(/,/, '.'),
        proteins: this.ingredientData.proteins.toString().replace(/,/, '.'),
        fats: this.ingredientData.fats.toString().replace(/,/, '.'),
        carbohydrates: this.ingredientData.carbohydrates.toString().replace(/,/, '.'),
      };
      await this.update(this.ingredientData);
      if (this.error) {
        this.showErrorMessage(this.error.reponse.message);
      }
      appLocalStorage.setItem('ingredient', this.ingredientData);
      this.showSuccessMessage(`Ингредиент успешно обновлен`);
    },
    selectCategoryFn(category) {
      this.ingredientData.category_id = category.id;
    },
    async searchCategoryFn(queryString, cb) {
      const filter = queryString ? { filter: { title: queryString }, limit: 100 } : { limit: 100 };
      await this.fetchCategories(filter);
      cb(this.categories);
    },
    selectUnitFn(unit) {
      this.ingredientData.unit_id = unit.id;
    },
    async searchUnitFn(queryString, cb) {
      const filter = queryString ? { filter: { title: queryString }, limit: 100 } : { limit: 100 };
      await this.fetchUnits(filter);
      cb(this.units.data);
    },
  },
};
</script>
